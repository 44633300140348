import Lottie from 'react-lottie';
import errorAnimation from '../../../assets/error_not_found_animation.json';

interface LottieProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  animationData: any;
  autoplay: boolean;
  loop: boolean;
  rendererSettings: {
    preserveAspectRatio: string;
  };
}

const defaultOptions: LottieProps = {
  animationData: errorAnimation,
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function NotFoundDisplay() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-4">
      <Lottie height={250} options={defaultOptions} width={500} />
    </div>
  );
}
