import { produce } from 'immer';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export type ModalType =
  | 'searchModal'
  | 'documentModal'
  | 'filesDownloadAllZipModal';

export interface ModalStore {
  modals: Record<ModalType, boolean>;
  setModal: (modal: ModalType, value: boolean) => void;
}

const defaultStore: Pick<ModalStore, 'modals'> = {
  modals: {
    documentModal: false,
    filesDownloadAllZipModal: false,
    searchModal: false,
  },
};

export const useModalStore = create<ModalStore>()(
  devtools(
    persist(
      (set) => ({
        ...defaultStore,
        setModal: (modal: ModalType, value: boolean) => {
          set(
            produce((state: ModalStore) => {
              state.modals[modal] = value;
            })
          );
        },
      }),
      {
        name: 'modals',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
