import { produce } from 'immer';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export type Language =
  | 'de-DE'
  | 'en-US'
  | 'hu-HU'
  | 'pl-PL'
  | 'ro-RO'
  | 'ru-RU'
  | 'sk-SK'
  | 'sr-SR'
  | 'tr-TR'
  | 'uk-UA'
  | 'zh-CN';

export type LanguageStore = {
  language: Language;
  setLanguage: (language: Language) => void;
};

export const useLanguageStore = create<LanguageStore>()(
  devtools(
    persist(
      (set) => ({
        language: 'en-US',
        setLanguage: (language: Language) => {
          set(
            produce((state: LanguageStore): void => {
              state.language = language;
            })
          );
        },
      }),
      {
        name: 'language',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
