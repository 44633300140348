import { NovuProvider } from "@novu/react";
import { useAuthContext } from "@/hooks/useAuth";

export type NotificationProviderProps = {
  children: React.ReactNode;
};

export function NotificationProvider({ children }: NotificationProviderProps) {
  const { user } = useAuthContext();

  if (!import.meta.env.VITE_NOVU_APP_ID || user._id === '') {
    return <>{children}</>;
  }

  return (
    <NovuProvider
      applicationIdentifier={import.meta.env.VITE_NOVU_APP_ID}
      backendUrl="https://eu.api.novu.co"
      socketUrl="https://eu.ws.novu.co"
      subscriberId={user?._id.toString()}
    >
      {children}
    </NovuProvider>
  );
}
