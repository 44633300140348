import { useMemo } from 'react';
import { getMenuIcon } from './layout/MenuIcon';
import { NavMain } from '@/components/nav-main';
import { NavUser } from '@/components/nav-user';
import { TeamSwitcher } from '@/components/team-switcher';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from '@/components/ui/sidebar';
import { useAuthContext } from '@/hooks/useAuth';
import { useMenus } from '@/hooks/useMenus';
import { useTranslation } from '@/hooks/useTranslation';
import { useClientStore } from '@/stores/client';
import { MenuItem } from '@/types/menus';

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const auth = useAuthContext();
  const { t, i18n } = useTranslation();
  const { createNavigation } = useMenus();

  const language = useMemo(
    () => i18n.resolvedLanguage ?? i18n.language,
    [i18n.resolvedLanguage, i18n.language]
  );

  const client = useClientStore((state) => state.client);
  const setMenu = useClientStore((state) => state.setMenu);
  const availableClients = useClientStore((state) => state.availableClients);

  const menuItems = useMemo(() => {
    const clientMenus = client?.menus ?? [];
    const newMenuItems: MenuItem[] = [];

    for (const menu of clientMenus) {
      const title = menu.definition?.title?.[language] ?? menu.title;
      const plural = menu.definition?.plural?.[language] ?? menu.title;

      const menuData: MenuItem = {
        icon: getMenuIcon({ icon: menu.icon }),
        name: plural,
      };

      if (menu.createEnabled) {
        menuData.basePath = `/resources/${menu.definitionName}`;
        menuData.children = [
          {
            href: `/resources/${menu.definitionName}/create`,
            name: t('common:menu.create', { resource: title.toLowerCase() }),
          },
          {
            href: `/resources/${menu.definitionName}`,
            name: t('common:menu.list', { resource: plural.toLowerCase() }),
          },
        ];
      } else {
        menuData.href = `/resources/${menu.definitionName}`;
      }

      newMenuItems.push(menuData);
    }

    const menu = createNavigation(
      newMenuItems,
      client?.modules || [],
      auth.user.role
    );

    setMenu(menu);

    return menu;
  }, [
    client?.menus,
    client?.modules,
    createNavigation,
    auth.user.role,
    setMenu,
    language,
    t,
  ]);

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher teams={availableClients} />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={menuItems} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={auth.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
