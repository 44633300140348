import { produce } from 'immer';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { ApiFile } from '@/types/shipment';

interface LocalFile extends ApiFile {
  shipmentId: string;
}

export type FileStore = {
  clearSelectedFile: () => void;
  selectedFile: LocalFile | null;
  setSelectedFile: (file: LocalFile) => void;
};

export const useFileStore = create<FileStore>()(
  devtools(
    persist(
      (set) => ({
        clearSelectedFile: () => {
          set(
            produce((state: FileStore): void => {
              state.selectedFile = null;
            })
          );
        },
        selectedFile: null,
        setSelectedFile: (file: LocalFile) => {
          set(
            produce((state: FileStore): void => {
              state.selectedFile = file;
            })
          );
        },
      }),
      {
        name: 'file',
      }
    )
  )
);
