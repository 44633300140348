import { baseURL } from './hooks/useApi';
import {
  AUDIT_LOGS,
  BUYER,
  CARBONE_URL,
  CBAM,
  DOCUMENT,
  EXPORT_XLSX,
  ORGANIZATION_SETTINGS,
  PROFILE,
  SELLER,
  SHIPMENTS,
  STATS,
  SUMMARY,
  TEMPLATES,
  USER,
  USERS,
} from './queryKeys';

export type QueryParams = Record<string, string | number | boolean> & {
  _end?: string;
  _from?: string;
  _limit?: number;
  _order?: string;
  _sort?: string;
  _start?: string;
  _to?: string;
  q?: string;
};

export const parseQueryParams = (params?: QueryParams) => {
  if (!params) return '';

  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return query ? `?${query}` : '';
};

export const generateUrlWithParams = (
  postURL: string,
  params?: QueryParams
) => {
  const base = `${baseURL}/${postURL}`;
  if (!params) return base;

  if ('_filterArchived' in params && params._filterArchived === false) {
    delete params._filterArchived;
    delete params._archived;
  }

  if ('_filterArchived' in params && params._filterArchived === true) {
    delete params._filterArchived;
  }

  const query = parseQueryParams(params);
  return `${base}${query}`;
};

export const endpoints = {
  // Audit
  auditLogs: (params?: QueryParams) =>
    generateUrlWithParams(AUDIT_LOGS, params),

  buyers: `${baseURL}/node/${BUYER}`,

  // Call Shipment module
  callShipmentModule: (id: string, name: string) =>
    `${baseURL}/${SHIPMENTS}/${id}/modules/${name}`,

  // Carbone
  carbone: (renderId: string) => `${CARBONE_URL}/${renderId}`,

  // CBAM
  cbam: (params?: QueryParams) => generateUrlWithParams(CBAM, params),

  createDocument: `${baseURL}/node/${DOCUMENT}`,

  createShipment: `${baseURL}/${SHIPMENTS}`,

  createUser: `${baseURL}/${USERS}`,

  deleteDocument: (id: string) => `${baseURL}/node/${DOCUMENT}/${id}`,

  deleteShipment: (id: string) => `${baseURL}/${SHIPMENTS}/${id}`,

  deleteUser: (id: string) => `${baseURL}/${USERS}/${id}`,

  document: (id: string) => `${baseURL}/node/${DOCUMENT}/${id}`,

  // Document
  documents: `${baseURL}/node/${DOCUMENT}`,

  downloadShipmentFile: (id: string, fileId: string) =>
    `${baseURL}/${SHIPMENTS}/${id}/files/${fileId}`,

  exportSummaryXlsx: () => `${baseURL}/${SHIPMENTS}/${SUMMARY}/${EXPORT_XLSX}`,

  // Export xlsx
  exportXlsx: (id: string) => `${baseURL}/${SHIPMENTS}/${id}/${EXPORT_XLSX}`,

  // General
  getBaseUrl: baseURL,

  // Inventory
  inventoryProducts: (params?: QueryParams) =>
    generateUrlWithParams('inventory/products', params),

  // AIS - Master Data
  masterData: (params?: QueryParams) =>
    generateUrlWithParams('node/master-data', params),

  // Organizations
  organizationSettings: (id?: string, params?: QueryParams) => {
    if (id) {
      return generateUrlWithParams(
        `node/${ORGANIZATION_SETTINGS}/${id}`,
        params
      );
    }
    return generateUrlWithParams(`node/${ORGANIZATION_SETTINGS}`, params);
  },

  // Profile
  profile: `${baseURL}/${USER}/${PROFILE}`,

  // Resources
  resources: (defName: string, params?: QueryParams) =>
    generateUrlWithParams(`node/${defName}`, params),

  // Schemas
  schemas: (params?: QueryParams) =>
    generateUrlWithParams('node/definitions', params),

  sellers: `${baseURL}/node/${SELLER}`,

  shipment: (id: string) => `${baseURL}/${SHIPMENTS}/${id}`,

  shipmentComments: (id: string, params?: QueryParams) =>
    generateUrlWithParams(`${SHIPMENTS}/${id}/comments`, params),

  shipmentFiles: (id: string, params?: QueryParams) =>
    generateUrlWithParams(`${SHIPMENTS}/${id}/files`, params),

  // Shipment Integration
  shipmentIntegration: (id: string, name: string) =>
    `${baseURL}/${SHIPMENTS}/${id}/integrations/${name}`,

  // Shipments
  shipments: (params?: QueryParams) => generateUrlWithParams(SHIPMENTS, params),

  shipmentSummary: (type: string, params?: QueryParams) =>
    generateUrlWithParams(`${SHIPMENTS}/${SUMMARY}/${type}`, params),

  // Stats
  stats: (params?: QueryParams) => generateUrlWithParams(STATS, params),

  // Shipments summary
  summary: (params?: QueryParams) =>
    generateUrlWithParams(`${SHIPMENTS}/${SUMMARY}`, params),

  // Templates
  templates: (params?: QueryParams) => generateUrlWithParams(TEMPLATES, params),

  updateDocument: (id: string) => `${baseURL}/node/${DOCUMENT}/${id}`,

  updateShipment: (id: string) => `${baseURL}/${SHIPMENTS}/${id}`,

  updateUser: (id: string) => `${baseURL}/${USERS}/${id}`,

  user: (id: string) => `${baseURL}/${USERS}/${id}`,

  // Users
  users: `${baseURL}/${USERS}`,

  warehouses: (params?: QueryParams) =>
    generateUrlWithParams('inventory/warehouses', params),
};
