import { useMemo } from 'react';
import { Inbox } from '@novu/react';
import { dark } from '@novu/react/themes';
import { useNavigate } from 'react-router-dom';
import { Bell } from './Bell';
import { useAuthContext } from '@/hooks/useAuth';
import { useTranslation } from '@/hooks/useTranslation';
import i18n from '@/i18n';
import { useTheme } from '@/layouts/ThemeProvider';

const tabs = [
  {
    filter: { tags: ["notifications"] },
    label: i18n.t('notifications:tabs.notifications'),
  },
  {
    filter: { tags: ['news'] },
    label: i18n.t('notifications:tabs.news'),
  },
  {
    filter: { tags: [] },
    label: i18n.t('notifications:tabs.all'),
  }
];

export function Novu() {
  const { t } = useTranslation('notifications');
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { theme } = useTheme();

  const localization = useMemo(() => {
    const bundle = i18n.getResourceBundle(i18n.language, 'notifications');
    if (i18n.language === 'hu-HU') {
      bundle['notifications.newNotifications'] =
        ({ notificationCount }: { notificationCount: number }) => {
          const countText = t('newNotifications.count', { count: notificationCount, postProcess: 'interval' });
          const notificationText = t('newNotifications.notification', { count: notificationCount });

          return `${countText} ${notificationText}`;
        };
    }

    return {
      locale: i18n.language,
      ...bundle,
    };
  }, [t]);

  if (user._id === '') {
    return null;
  }

  if (!import.meta.env.VITE_NOVU_APP_ID) {
    return null;
  }

  return (
    <Inbox
      appearance={{ baseTheme: theme === 'dark' ? dark : undefined }}
      applicationIdentifier={import.meta.env.VITE_NOVU_APP_ID}
      backendUrl="https://eu.api.novu.co"
      localization={{
        ...localization,
        'notifications.newNotifications': ({ notificationCount }: { notificationCount: number }) => {
          const countText = t('newNotifications.count', { count: notificationCount, postProcess: 'interval' });
          const notificationText = t('newNotifications.notification', { count: notificationCount });

          console.log('notificationCount', notificationCount);
          console.log('countText', countText);
          console.log('notificationText', notificationText);
          console.log('localization', localization['notifications.newNotifications']);

          return `${countText} ${notificationText}`;
        },
      }}
      renderBell={(notificationCount) => <Bell count={notificationCount} />}
      routerPush={(path: string) => navigate(path)}
      socketUrl="https://eu.ws.novu.co"
      subscriberId={user?.subscriberId ?? ''}
      tabs={tabs}
      onPrimaryActionClick={(notification) => {
        console.log('Primary action clicked', notification);
        console.log('red data', notification.redirect);
      }}
    />
  );
}
