'use client';

import { useCallback } from 'react';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/components/ui/sidebar';
import { SIDEBAR_ICON_SIZE } from '@/constants';
import { Menu } from '@/types/menus';

export function NavMain({ items }: { items: Menu }) {
  const isActive = useCallback(() => {
    return false;
  }, []);

  return (
    <SidebarGroup>
      <SidebarMenu>
        {items.map((item, index) =>
          item.children?.length ? (
            <Collapsible
              asChild
              className="group/collapsible"
              defaultOpen={isActive()}
              key={index}
            >
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton tooltip={item.name}>
                    {item.icon && <item.icon size={SIDEBAR_ICON_SIZE} />}
                    <span>{item.name}</span>
                    <ChevronRightIcon className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenuSub>
                    {item.children?.map((subItem, subIndex) => (
                      <SidebarMenuSubItem key={subIndex}>
                        <SidebarMenuSubButton asChild>
                          <NavLink
                            className={({ isActive }) =>
                              clsx({ 'bg-primary/30': isActive })
                            }
                            to={subItem.href ?? '#'}
                          >
                            <span>{subItem.name}</span>
                          </NavLink>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>
          ) : (
            <SidebarMenuItem key={item.name}>
              <SidebarMenuButton asChild tooltip={item.name}>
                <NavLink
                  className={({ isActive }) =>
                    clsx({ 'bg-primary/30': isActive })
                  }
                  to={item.href ?? '#'}
                >
                  <item.icon size={SIDEBAR_ICON_SIZE} />
                  <span>{item.name}</span>
                </NavLink>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
}
