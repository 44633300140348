import { useCallback } from 'react';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { DiceBearAvatar } from './DiceBearAvatar';
import { ScrollArea } from './ui/scroll-area';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@/components/ui/sidebar';
import { ClientType } from '@/schemas/client';
import { ClientTypeWithRole, useClientStore } from '@/stores/client';

export function TeamSwitcher({ teams }: { teams: ClientType[] }) {
  const { isMobile } = useSidebar();

  const setClient = useClientStore((state) => state.setClient);
  const selectedClient = useClientStore((state) => state.client);

  const handleChange = useCallback(
    (client: ClientTypeWithRole) => {
      setClient(client);

      // TODO: replace with react-router navigate when we clean the state handling
      window.location.replace('/');
    },
    [setClient]
  );

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground !pr-2"
              size="lg"
            >
              <DiceBearAvatar
                collection="glass"
                seed={selectedClient?.name ?? 'unknown'}
              />
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">
                  {selectedClient?.name}
                </span>
              </div>
              <CaretSortIcon className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="start"
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? 'bottom' : 'right'}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Clients
            </DropdownMenuLabel>
            <ScrollArea className="h-72">
              {teams.map((team) => (
                <DropdownMenuItem
                  className="gap-2 p-2"
                  key={team.name}
                  onClick={() => handleChange(team)}
                >
                  <div className="flex size-6 items-center justify-center">
                    <DiceBearAvatar collection="glass" seed={team.name} />
                  </div>
                  {team.name}
                </DropdownMenuItem>
              ))}
            </ScrollArea>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
