import {
  Fragment,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { X } from 'lucide-react';
import { useModalContext } from '../../hooks/useModalContext';
import { ModalType, useModalStore } from '../../stores/modals';

interface ModalContext {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
  modalType: ModalType;
  onClose?: () => void;
  showCloseButton?: boolean;
  title?: string;
}

function Modal({
  title,
  children,
  modalType,
  className = '',
  innerClassName = '',
  onClose,
  showCloseButton,
}: ModalContext) {
  const { isModalOpen, closeModal } = useModalContext();
  const modals = useModalStore((store) => store.modals);
  const ref = useRef<HTMLInputElement>(null);

  const handleClose = useCallback(() => {
    closeModal(modalType);
    onClose?.();
  }, [closeModal, modalType, onClose]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.checked = modals[modalType];
    }
  }, [modalType, modals]);

  if (modals[modalType] === false) {
    return null;
  }

  return (
    <Transition appear as={Fragment} show={isModalOpen}>
      <Dialog
        as="div"
        className={clsx('relative z-50', className)}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  'relative w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all',
                  innerClassName
                )}
              >
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                )}
                {children}
                {showCloseButton && (
                  <button
                    className="absolute top-0 right-0 p-4 z-100 text-gray-500 hover:text-gray-800"
                    onClick={handleClose}
                  >
                    <X size={16} />
                  </button>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
