import { Country } from './types/common';
import { DocumentLanguage } from './types/document';
import { ArchivationStatusTypes, CMRType, CMRTypeData } from './types/shipment';
import { getFilterYears } from './utils/date';

export const SEARCH_DEBOUNCED_TIME = 500;
export const SEARCH_LIMIT = 10;
export const SHOWN_PROP_NUM = 5;

export const PAGINATION_STORAGE_KEY = 'paginatedTable';

export const REQUIRED_FIELD_KEY = 'required';

export const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;

// carbone.io document ids
export const CMR_DOCUMENT_ID = 'CMR_DOCUMENT_ID';
export const AD_HOC_CUSTOMS_DOCUMENT_ID =
  '245fd3e332afb012d53b493264df9a20edb080de339b494c75fd4f2c35de63be';
export const CLEARANCE_CERTIFICATE_EN_HU_DOCUMENT_ID =
  'b35a99c532809ca0ab838111cc79fdda75a1744853f438bce468d8d15230eb3b';
export const CLEARANCE_CERTIFICATE_EN_CR_DOCUMENT_ID =
  'e692425202c4b4e356a2b805c59c3e7e7e78edcdd7be7bea1f98a4197b8b855f';
export const PERMANENT_CUSTOMS_DOCUMENT_ID =
  '6abf047d62f5a96265e6652372b2bc2c4647f25fa535efea0bdb7964ccc3ce35';
export const MTC_DOCUMENT_ID =
  '7822dabb418101ae40361d477640f569e362f9735ee8d89d0864b15f04f083f1';
export const SHIPMENT_SUMMARY_CUSTOMS_DOCUMENT_ID =
  'ae3300d12330a90a8eb5cd71c81d9f8e120ab284772d61e66a8eaee6d2d91d89';
export const VAT_DECLARATION_DOCUMENT_ID =
  '5ca3e608e6edb43f02b38a8a7467d6c00c8e7c3d9a348958cb7ac08f64bfa8f9';
export const TRANSPORT_COST_ID =
  'b56ea17a62bce17d0b0a81edf0574dba6f9e7166c1f188cc40ddfc560b08dc66';
export const SINGLE_SHIPMENT_EXPORT_DOCUMENT_ID =
  '288aea054e5e730c7e6f1773d12fc2448668360ab114258f1b9cc2089cb3f1f7';
export const VIES_CONFIRMATION_DOCUMENT_ID =
  'a146670f87584e8a1f063e46477216c81a0f2455c5e59894cda8fca62851600a';

export const COMMERCIAL_INVOICE_EN_DOCUMENT_ID =
  '3a52786b29dee016368d802463d0a0efdebf49f09291891d93b949fc678100bb';
export const COMMERCIAL_INVOICE_HU_DOCUMENT_ID =
  '29541b6b5937d5d6e3994313087c99290517d5ca4133a125b6a4db3b9632bbb3';
export const COMMERCIAL_INVOICE_ZH_DOCUMENT_ID =
  '550bb1024c7c72f15262cbad8add941282a9515aec1db05597c9669974048506';

export const commercialInvoiceIdByLanguage: Record<DocumentLanguage, string> = {
  en: COMMERCIAL_INVOICE_EN_DOCUMENT_ID,
  hu: COMMERCIAL_INVOICE_HU_DOCUMENT_ID,
  zh: COMMERCIAL_INVOICE_ZH_DOCUMENT_ID,
};

export const HIDE_TRANSPORT_COST_INCOTERMS = [
  'CPT',
  'CIP',
  'DAP',
  'DPU',
  'DDP',
];

export const ZIP_DOWNLOAD_DOCUMENT_IDS = [
  'AD_HOC_CUSTOMS_DOCUMENT_ID',
  'MTC_DOCUMENT_ID',
  'TRANSPORT_COST_ID',
  'VIES_CONFIRMATION_DOCUMENT_ID',
  'CMR_DOCUMENT_ID',
];

// TODO: these are test values, replace them with real calculations
export const CBAM_RATIO = 1.97;
export const CBAM_PRICE = 12.5;
export const DE_MINIMIS_TRHESHOLD = 150;
export const DIRECT_RATIO = 0.7;

export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

export const CMR_LETTER_TRUNCATED_LENGTH = 52;

export const DASHBOARD_DEFAULT_PARAMS = {
  _limit: 10,
  _order: 'desc',
  _sort: 'created',
};

export const SHIPMENTS_LIST_DEFAULT_PARAMS = {
  _limit: 2000,
  _order: 'desc',
  _sort: 'created',
};

export const BASE_RESPONSE = {
  count: 0,
  data: [],
};

export const CMR_PRODUCTS_MAX_COUNT = 8;

export const COUNTRIES: Country[] = [
  {
    title: {
      'en-US': 'Austria',
      'hu-HU': 'Ausztria',
    },
    value: 'austria',
  },
  {
    title: {
      'en-US': 'Hungary',
      'hu-HU': 'Magyarország',
    },
    value: 'hungary',
  },
  {
    title: {
      'en-US': 'Serbia',
      'hu-HU': 'Szerbia',
    },
    value: 'serbia',
  },
  {
    title: {
      'en-US': 'Slovakia',
      'hu-HU': 'Szlovákia',
    },
    value: 'slovakia',
  },
  {
    title: {
      'en-US': 'Slovenia',
      'hu-HU': 'Szlovénia',
    },
    value: 'slovenia',
  },
];

export const CMRTypeDataByCMRType: Record<CMRType, CMRTypeData> = {
  carrier: {
    color: '#00A74F',
    number: '3',
    titles: [
      'A szállító példánya',
      'Specimen for carrier',
      'Exemplar für Lieferanten',
    ],
  },
  consignee: {
    color: '#0182C7',
    number: '2',
    titles: [
      'A címzett példánya',
      'Specimen for consignee',
      'Exemplar für Empfänger',
    ],
  },
  consignor: {
    color: '#ED1848',
    number: '1',
    titles: [
      'A feladó példánya',
      'Specimen for consignor',
      'Exemplar für Absender',
    ],
  },
};

export const ZALAVAM = {
  address: {
    address: 'Fő út 7.',
    city: 'Nagykanizsa',
    counties: [
      {
        city: 'Zalaegerszeg',
        name: 'Zala',
      },
      {
        city: 'Szeged',
        name: 'Csongrád-Csanád',
      },
      {
        city: 'Kecskemét',
        name: 'Bács-Kiskun',
      },
    ],
    country: 'Hungary',
    zip: '8800',
  },
  agent: {
    aeoc: '611000T5000003633',
    companyName: 'Zalavám Kft',
    name: 'Zoltan dr.Varga',
    position: 'Customs and logistics Manager',
  },
  email: 'vamzoll@vamzoll.hu',
  eori: 'HU0018866379',
  euTaxNumber: 'HU11691541',
  financialInformation: {
    bankAccount: '11763495-54243880-00000000',
    bankName: 'OTP Bank - Hungary OTPVHUHB',
  },
  name: 'Zalavám Kft',
  phone: '+36 30 203 9236',
  reps: [
    {
      name: 'Viziné Varga Márta EV.',
      place: 'Szeged',
    },
    {
      name: 'Globallog',
      place: 'Szeged',
    },
  ],
};

export const YEARS = getFilterYears();

export const QUARTERS: Record<string, number> = {
  Q1: 1,
  Q2: 2,
  Q3: 3,
  Q4: 4,
} as const;

export const MONTHS: Record<string, number> = {
  april: 3,
  august: 7,
  december: 11,
  february: 1,
  january: 0,
  july: 6,
  june: 5,
  march: 2,
  may: 4,
  november: 10,
  october: 9,
  september: 8,
} as const;

export const SHIPMENT_ARCHIVATION_FILTERS: Record<
  ArchivationStatusTypes,
  string
> = {
  all: 'all',
  archived: 'archived',
  'not-archived': 'not-archived',
} as const;

export const incoterms = [
  'exw',
  'fca',
  'fas',
  'fob',
  'cpt',
  'cip',
  'cfr',
  'cif',
  'dap',
  'dpu',
  'ddp',
];

export const documentLanguages: DocumentLanguage[] = ['en', 'hu', 'zh'];

export const commercialInvoiceDataSample = {
  address: {
    city: 'Shanghai',
    country: 'China',
    postalCode: '200000',
    street: '123 Nanjing Road',
  },
  billTo: {
    address: {
      city: 'Munich',
      country: 'Germany',
      postalCode: '80331',
      street: 'Hauptstrasse 45',
    },
    name: 'Global Trading GmbH',
  },
  blDate: '2025-02-20',
  blNumber: 'SHIP2025001',
  consignee: {
    address: {
      city: 'Hamburg',
      country: 'Germany',
      postalCode: '20457',
      street: 'Hafenstrasse 123',
    },
    contact: {
      email: 'h.schmidt@edc.com',
      name: 'Hans Schmidt',
    },
    name: 'European Distribution Center',
  },
  country: 'China',
  data: [
    {
      amount: 15500,
      partNumber: 'PT-001-A',
      pkg: 'Carton',
      pkgNumber: 100,
      poNumber: 'PO2025-001',
      price: 15.5,
      quantity: 1000,
    },
    {
      amount: 12875,
      partNumber: 'PT-002-B',
      pkg: 'Pallet',
      pkgNumber: 50,
      poNumber: 'PO2025-002',
      price: 25.75,
      quantity: 500,
    },
    {
      amount: 12875,
      partNumber: 'PT-002-B',
      pkg: 'Pallet',
      pkgNumber: 50,
      poNumber: 'PO2025-002',
      price: 25.75,
      quantity: 500,
    },
  ],
  grossWeight: '1250.00',
  incoterm: 'FOB Shanghai',
  invoiceClient: {
    address: {
      city: 'Shanghai',
      country: 'China',
      postalCode: '200000',
      street: '123 Nanjing Road',
    },
    bankInstitution: 'Bank of China',
    beneficiaryAccount: '123456789012345678',
    email: 'contact@samplecompany.com',
    fax: '+86 21 1234 5679',
    name: 'Sample Company Ltd.',
    phone: '+86 21 1234 5678',
    swift: 'BKCHCNBJ',
    taxNumber: '91310000XXXXXXXX3B',
  },
  invoiceDate: '2025-02-18',
  invoiceNumber: 'INV-2025-001',
  sumAmount: 28375,
  sumPkgNumber: 150,
  sumQuantity: 1500,
  supplierCode: 'MPC-001',
  supplierName: 'Manufacturing Partner Co.',
  volume: '12.50',
};

export const documents = [
  'invoice',
  'transport-invoice',
  'eur1',
  'cmr',
  'way-of-goods',
  'customs-clearance-resolution',
  'signed-eu-invoice',
  'signed-cmr',
  'signed-ad-hoc-authentication',
  'signed-vies',
];

export const SIDEBAR_ICON_SIZE = 16;
