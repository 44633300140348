import { useMemo } from 'react';
import { glass, initials, pixelArt } from '@dicebear/collection';
import { createAvatar, StyleOptions } from '@dicebear/core';
import { AnyObject } from '@/utils/types';

const COLLECTIONS = {
  glass: glass,
  initials: initials,
  'pixel-art': pixelArt,
};

const COLLECTION_OPTIONS: Record<string, AnyObject> = {
  glass: {
    radius: 50,
  },
  initials: {
    radius: 50,
  },
};

export function DiceBearAvatar({
  seed,
  collection = 'initials',
  options,
}: {
  collection?: keyof typeof COLLECTIONS;
  options?: StyleOptions<(typeof COLLECTIONS)[typeof collection]>;
  seed: string;
}) {
  const avatar = useMemo(() => {
    return createAvatar(COLLECTIONS[collection], {
      seed,
      size: 24,
      ...(COLLECTION_OPTIONS[collection] ?? {}),
      ...options,
    }).toDataUri();
  }, [seed, collection, options]);

  return <img alt="Avatar" src={avatar} />;
}
