import { produce } from 'immer';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { UserType } from '../schemas/user';

export type UserStore = {
  isLoggedIn: boolean;
  login: (user: UserType, token: string) => void;
  logout: () => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setToken: (token: string) => void;
  setUser: (user: UserType) => void;
  token?: string;
  user: UserType | null;
};

const defaultStore: Pick<UserStore, 'user' | 'token' | 'isLoggedIn'> = {
  isLoggedIn: false,
  token: undefined,
  user: null,
};

export const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set) => ({
        ...defaultStore,
        login: (user: UserType, token: string) => {
          set(
            produce((state: UserStore) => {
              state.user = user;
              state.isLoggedIn = true;
              state.token = token;
            })
          );
        },
        logout: () => {
          set(
            produce((state: UserStore) => {
              state.user = null;
              state.isLoggedIn = false;
              state.token = undefined;
            })
          );
        },
        setIsLoggedIn: (isLoggedIn: boolean) => {
          set(
            produce((state: UserStore) => {
              state.isLoggedIn = isLoggedIn;
            })
          );
        },
        setToken: (token: string) => {
          set(
            produce((state: UserStore) => {
              state.token = token;
            })
          );
        },
        setUser: (user: UserType) => {
          set(
            produce((state: UserStore) => {
              state.user = user;
              state.isLoggedIn = true;
            })
          );
        },
      }),
      {
        name: 'user',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
