import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
  subDays,
} from 'date-fns';
import { DateRange } from 'react-day-picker';
import { DATE_REGEX } from '@/constants';

export const convertDateToStandardFormat = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const getYesterday = (date?: Date) => {
  return subDays(date ?? new Date(), 1);
};

export const getStartOfWeek = (date?: Date) => {
  return startOfWeek(date ?? new Date(), { weekStartsOn: 1 });
};

export const getStartOfMonth = (date?: Date) => {
  return startOfMonth(date ?? new Date());
};

export const getStartOfYear = (date?: Date) => {
  return startOfYear(date ?? new Date());
};

export const getLastWeekStart = (date?: Date) => {
  const lastWeekDate = sub(date ?? new Date(), { days: 7 });
  return startOfWeek(lastWeekDate, { weekStartsOn: 1 });
};

export const getLastWeekEnd = (date?: Date) => {
  const lastWeekDate = sub(date ?? new Date(), { days: 7 });
  return endOfWeek(lastWeekDate, { weekStartsOn: 1 });
};

export const getLastMonthStart = (date?: Date) => {
  const lastMonthDate = sub(date ?? new Date(), { months: 1 });
  return startOfMonth(lastMonthDate);
};

export const getLastMonthEnd = (date?: Date) => {
  const lastMonthDate = sub(date ?? new Date(), { months: 1 });
  return endOfMonth(lastMonthDate);
};

export const getLastYearStart = (date?: Date) => {
  const lastYearDate = sub(date ?? new Date(), { years: 1 });
  return startOfYear(lastYearDate);
};

export const getLastYearEnd = (date?: Date) => {
  const lastYearDate = sub(date ?? new Date(), { years: 1 });
  return endOfYear(lastYearDate);
};

export const detectDateString = (value: string) => {
  const isDate = DATE_REGEX.test(value);

  if (isDate) {
    return new Date(value).toLocaleString();
  }

  return value;
};

export const getQuarterDates = (year: number, quarter: number): DateRange => {
  const startDate = new Date(year, (quarter - 1) * 3, 1);
  const endDate = new Date(year, quarter * 3, 0);

  return {
    from: startDate,
    to: endDate,
  };
};

export const getMonthDates = (year: number, month: number): DateRange => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);

  return {
    from: startDate,
    to: endDate,
  };
};

export const getDateDetails = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const quarter = Math.ceil(month / 3);
  const monthsName = format(date, 'LLLL');

  return {
    monthsName,
    monthsNumber: month,
    quarter,
    year,
  };
};

export const getFilterYears = () => {
  const startYear = 2022;
  const currentYear = new Date().getFullYear();
  const years: Record<string, number> = {};

  for (let year = startYear; year <= currentYear; year++) {
    years[year.toString()] = year;
  }

  return years;
};
