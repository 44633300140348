import { createContext } from 'react';
import { ModalType } from '../stores/modals';

interface ModalContextProps {
  closeModal: (modalType: ModalType) => void;
  isModalOpen: boolean;
  openModal: (modalType: ModalType) => void;
}

const ModalContextDefault: ModalContextProps = {
  closeModal: () => {
    return;
  },
  isModalOpen: false,
  openModal: () => {
    return;
  },
};

export const ModalContext = createContext(ModalContextDefault);
