'use client';

import { useCallback } from 'react';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { LogOut, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';
import { DiceBearAvatar } from './DiceBearAvatar';
import { LanguageSelector } from './layout/LanguageSelector';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@/components/ui/sidebar';
import { SIDEBAR_ICON_SIZE } from '@/constants';
import { useAuthContext } from '@/hooks/useAuth';
import { useTranslation } from '@/hooks/useTranslation';
import { UserType } from '@/schemas/user';

export function NavUser({ user }: { user: UserType }) {
  const { t } = useTranslation();
  const { isMobile } = useSidebar();

  const auth = useAuthContext();

  const logout = useCallback(async () => {
    await auth.logout();
  }, [auth]);

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              size="lg"
            >
              <DiceBearAvatar seed={user.name} />
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{user.name}</span>
                <span className="truncate text-xs">{user.email}</span>
              </div>
              <CaretSortIcon className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? 'bottom' : 'right'}
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal flex items-center gap-4">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <DiceBearAvatar seed={user.name} />
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{user.name}</span>
                  <span className="truncate text-xs">{user.email}</span>
                </div>
              </div>
              <LanguageSelector />
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem asChild>
                <Link to="/user/profile">
                  <Settings size={SIDEBAR_ICON_SIZE} />
                  {t('users:nav.profile')}
                </Link>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={logout}>
              <LogOut size={SIDEBAR_ICON_SIZE} />
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
