import clsx from 'clsx';
import { SidebarTrigger } from '../ui/sidebar';
import { ColorSwitch } from './ColorSwitch';
import { CommandMenu } from './CommandMenu';
import { Inbox } from './notification-center';

export type HeaderProps = {
  onMenuClick?: () => void;
  sidebarOpen?: boolean;
};

export const HeaderV2 = () => {
  return (
    <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
      <div
        className={clsx(
          'flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11'
        )}
      >
        <div className="flex gap-4">
          <SidebarTrigger className="-ml-1" />
          <CommandMenu />
        </div>
        <div className="flex items-center gap-x-4 lg:gap-x-6 pr-1">
          <ColorSwitch />
          <Inbox />
        </div>
      </div>
    </header>
  );
};
