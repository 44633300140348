
// eslint-disable-next-line import/order
import { scan } from 'react-scan';
import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import './index.css';
import './i18n';
import NotFoundDisplay from './components/common/Error/NotFoundDisplay';
import RootErrorBoundary from './components/common/Error/RootErrorBoundary';
import { baseURL } from './hooks/useApi';
import AppLayout from './layouts/App';
import { appLayoutLoader } from './layouts/appLayoutLoader';
import Layout from './layouts/Default';
import {
  IS_SENTRY_AVAILABLE,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
} from './utils/sentry';

let wrappedCreateBrowserRouter = createBrowserRouter;

if (import.meta.hot) {
  scan();
}

if (IS_SENTRY_AVAILABLE) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,

    // Session Replay
    replaysSessionSampleRate: 0.1,

    tracePropagationTargets: [baseURL],
    // Performance Monitoring
    tracesSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  wrappedCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);
}

const router = wrappedCreateBrowserRouter([
  {
    children: [
      {
        lazy: () => import('@/screens/Home'),
        path: '/',
      },
      {
        children: [
          {
            lazy: () => import('@/screens/shipment/ShipmentList'),
            path: '/shipments',
          },
          {
            lazy: () => import('@/screens/shipment/ShipmentCreate'),
            path: '/shipments/create',
          },
          {
            lazy: () => import('@/screens/shipment/ShipmentDetails'),
            path: '/shipments/edit/:id',
          },
          {
            lazy: () => import('@/screens/shipment/ShipmentSummary'),
            path: '/shipments/summary',
          },
        ],
        path: '/shipments',
      },
      {
        children: [
          {
            lazy: () => import('@/screens/shipment/CBAMSummary'),
            path: '/summary',
          },
        ],
        path: '/summary',
      },
      {
        children: [
          {
            children: [
              {
                lazy: () => import('@/screens/inventory/Products'),
                path: '/inventory/products',
              },
              {
                lazy: () => import('@/screens/inventory/ShowProduct'),
                path: '/inventory/products/:id/view',
              },
            ],
            path: '/inventory/products',
          },
          {
            children: [
              {
                lazy: () => import('@/screens/inventory/Procurements'),
                path: '/inventory/procurements',
              },
              {
                lazy: () => import('@/screens/inventory/CreateProcurement'),
                path: '/inventory/procurements/create',
              },
              {
                lazy: () => import('@/screens/inventory/ShowProcurement'),
                path: '/inventory/procurements/:id/view',
              },
            ],
            path: '/inventory/procurements',
          },
          {
            children: [
              {
                lazy: () => import('@/screens/inventory/Outbound'),
                path: '/inventory/outbound',
              },
              {
                lazy: () => import('@/screens/inventory/CreateOutbound'),
                path: '/inventory/outbound/create',
              },
              {
                lazy: () => import('@/screens/inventory/ShowOutbound'),
                path: '/inventory/outbound/:id/view',
              },
            ],
            path: '/inventory/outbound',
          },
          {
            children: [
              {
                lazy: () => import('@/screens/inventory/Transfers'),
                path: '/inventory/transfers',
              },
              {
                lazy: () => import('@/screens/inventory/CreateTransfer'),
                path: '/inventory/transfers/create',
              },
              {
                lazy: () => import('@/screens/inventory/ShowTransfer'),
                path: '/inventory/transfers/:id/view',
              },
            ],
            path: '/inventory/transfers',
          },
          {
            children: [
              {
                lazy: () => import('@/screens/inventory/Warehouses'),
                path: '/inventory/warehouses',
              },
              {
                lazy: () => import('@/screens/inventory/CreateWarehouse'),
                path: '/inventory/warehouses/create',
              },
              {
                lazy: () => import('@/screens/inventory/EditWarehouse'),
                path: '/inventory/warehouses/:id/edit',
              },
            ],
            path: '/inventory/warehouses',
          },
        ],
        path: '/inventory',
      },
      {
        children: [
          {
            children: [
              {
                lazy: () => import('@/screens/cbam/Reports'),
                path: '/cbam/reports',
              },
              {
                lazy: () => import('@/screens/cbam/CreateReport'),
                path: '/cbam/reports/create',
              },
            ],
            path: '/cbam/reports',
          },
        ],
        path: '/cbam',
      },
      {
        children: [
          {
            lazy: () => import('@/screens/cbam-report-generator/Create'),
            path: '/cbam-report-generator',
          },
        ],
        path: '/cbam-report-generator',
      },
      {
        children: [
          {
            lazy: () => import('@/screens/resource/ResourceList'),
            path: '/resources/:resource',
          },
          {
            lazy: () => import('@/screens/resource/ResourceCreate'),
            path: '/resources/:resource/create',
          },
          {
            lazy: () => import('@/screens/resource/ResourceEdit'),
            path: '/resources/:resource/:id/edit',
          },
        ],
        path: '/resources',
      },
      {
        children: [
          {
            lazy: () => import('@/screens/user/Profile'),
            path: '/user/profile',
          },
        ],
        path: '/user',
      },
      {
        children: [
          {
            lazy: () => import('@/screens/organization/Settings'),
            path: '/organization/settings',
          },
        ],
        path: '/organization',
      },
      {
        children: [
          {
            children: [
              {
                lazy: () => import('@/screens/admin/team/Team'),
                path: '/admin/users',
              },
              {
                lazy: () => import('@/screens/admin/team/CreateUser'),
                path: '/admin/users/create',
              },
              {
                lazy: () => import('@/screens/admin/team/EditUser'),
                path: '/admin/users/:id/edit',
              },
            ],
            path: '/admin/users',
          },
          {
            lazy: () => import('@/screens/admin/AuditLog'),
            path: '/admin/audit-log',
          },
        ],
        path: '/admin',
      },
      {
        children: [
          {
            children: [
              {
                lazy: () => import('@/screens/root/clients/ListClients'),
                path: '/root/clients',
              },
              {
                lazy: () => import('@/screens/root/clients/CreateClient'),
                path: '/root/clients/create',
              },
              {
                lazy: () => import('@/screens/root/clients/EditClient'),
                path: '/root/clients/:id/edit',
              },
            ],
            path: '/root/clients',
          },
          {
            children: [
              {
                lazy: () => import('@/screens/root/cbam/ListCBAMData'),
                path: '/root/cbam',
              },
              {
                lazy: () => import('@/screens/root/cbam/ImportCBAMData'),
                path: '/root/cbam/import',
              },
            ],
            path: '/root/cbam',
          },
        ],
        path: '/root',
      },
      {
        element: <NotFoundDisplay />,
        path: '*',
      },
    ],
    Component: AppLayout,
    errorElement: <RootErrorBoundary />,
    id: 'root',
    loader: appLayoutLoader,
  },
  {
    lazy: () => import('@/screens/auth/AcceptInvitation'),
    path: '/accept-invitation',
  },
  {
    lazy: () => import('@/screens/auth/Login'),
    path: '/login',
  },
  {
    lazy: () => import('@/screens/auth/Registration'),
    path: '/registration',
  },
  {
    lazy: () => import('@/screens/auth/PreRegistration'),
    path: '/pre-registration',
  },
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Layout>
      <RouterProvider router={router} />
    </Layout>
  </React.StrictMode>
);
