import Lottie from 'react-lottie';
import errorAnimation from '../../../assets/error_screen_animation.json';
import { Button } from '@/components/ui/button';
import { useTranslation } from '@/hooks/useTranslation';

interface LottieProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  animationData: any;
  autoplay: boolean;
  loop: boolean;
  rendererSettings: {
    preserveAspectRatio: string;
  };
}

const defaultOptions: LottieProps = {
  animationData: errorAnimation,
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function ErrorDisplay({
  errorMessage,
}: {
  errorMessage?: string;
}) {
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  };

  const handleHomeNavigation = () => {
    window.location.href = '/';
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-4">
      <h1 className="text-xl">{t('common:errorBoundaryTitle')}</h1>
      <h3>
        {t('common:errorBoundaryMessage', {
          message: errorMessage ?? '-',
        })}
      </h3>
      <Lottie height={400} options={defaultOptions} width={400} />
      <div className="flex gap-4">
        <Button onClick={handleReload}>{t('common:reload')}</Button>
        <Button variant="outline" onClick={handleHomeNavigation}>
          {t('common:goToHome')}
        </Button>
      </div>
    </div>
  );
}
