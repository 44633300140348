import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// TODO: Add growthbook when feature flagging is enabled
/* import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import {
  GROWTHBOOK_API_HOST,
  IS_GROWTBOOK_DEV_MODE_ENABLED,
  IS_GROWTHBOOK_AVAILABLE,
} from '@/utils/growthbook'; */
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Outlet, useNavigate } from 'react-router-dom';
import { SchemaProvider } from './SchemaProvider';
import { ThemeTypes, useTheme } from './ThemeProvider';
import { AppSidebar } from '@/components/app-sidebar';
import { HeaderV2 } from '@/components/layout/HeaderV2';
import { NotificationProvider } from '@/components/layout/notification-center';
import RequireAuth from '@/components/RequireAuth';
import { DocumentModal } from '@/components/shipment/document/DocumentModal';
import { Sheet } from '@/components/ui/sheet';
import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar';
import { Toaster } from '@/components/ui/sonner';
import { TooltipProvider } from '@/components/ui/tooltip';
import { useAuthContext } from '@/hooks/useAuth';
import { ModalProvider } from '@/providers/ModalProvider';
import { useClientStore } from '@/stores/client';
import { IS_SENTRY_AVAILABLE, SENTRY_DSN } from '@/utils/sentry';

/* const growthbook = new GrowthBook({
  enabled: IS_GROWTHBOOK_AVAILABLE,
  apiHost: GROWTHBOOK_API_HOST,
  clientKey: 'sdk-eUpuuNwMmz2jIrVP',
  enableDevMode: IS_GROWTBOOK_DEV_MODE_ENABLED,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
}); */

const queryClient = new QueryClient();

export function AppLayoutV2() {
  const auth = useAuthContext();
  const { theme } = useTheme();

  const client = useClientStore((state) => state.client);
  const navigate = useNavigate();

  /* useEffect(() => {
    if (IS_GROWTHBOOK_AVAILABLE) {
      growthbook.loadFeatures();
    }
  }, []); */

  useEffect(() => {
    if (IS_SENTRY_AVAILABLE && auth.user._id !== '') {
      console.log('RUN SENTRY');

      Sentry.setUser({
        id: auth.user._id,
        name: auth.user.name,
        username: auth.user.email,
      });

      Sentry.init({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beforeSend(event: any) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id });
          }
          return event;
        },

        dsn: SENTRY_DSN,
      });
    }
  }, [auth]);

  if (!client) {
    navigate('/login');
  }

  return (
    // <GrowthBookProvider growthbook={growthbook}>
    <TooltipProvider>
      <DndProvider backend={HTML5Backend}>
        <Sheet>
          <RequireAuth>
            <ModalProvider>
              <QueryClientProvider client={queryClient}>
                <SchemaProvider>
                  <NotificationProvider>
                    <SidebarProvider>
                      <AppSidebar />
                      <SidebarInset>
                        <HeaderV2 />
                        {/* <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
                          <div className="flex items-center gap-2 px-4">
                            <SidebarTrigger className="-ml-1" />
                            <Separator orientation="vertical" className="mr-2 h-4" />
                          </div>
                        </header> */}
                        <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
                          <Outlet />
                        </div>
                        <DocumentModal />
                      </SidebarInset>
                    </SidebarProvider>
                  </NotificationProvider>
                </SchemaProvider>
              </QueryClientProvider>
            </ModalProvider>
          </RequireAuth>
        </Sheet>
      </DndProvider>
      <Toaster closeButton richColors theme={theme as ThemeTypes} />
    </TooltipProvider>
    // </GrowthBookProvider>
  );
}
